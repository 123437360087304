import React, { createContext, useContext, useState } from 'react';
import { globalHistory } from '@reach/router';

export const RouteContext = createContext();

export const RouteProvider = ({ children }) => {
  const [currentRoute, setCurrentRoute] = useState(globalHistory.location.pathname);
  const [previousRoute, setPreviousRoute] = useState('');
  const [targetRoute, setTargetRoute] = useState(''); // Add targetRoute state



  globalHistory.listen(({ location }) => {
    setPreviousRoute(currentRoute);
    setCurrentRoute(location.pathname);
  });

  // Function to update target route
  const updateTargetRoute = (path) => {
    setTargetRoute(path);
    console.log('setTargetRoute ->'+path)
  };



  return (
    <RouteContext.Provider value={{ previousRoute, targetRoute, updateTargetRoute }}>
      {children}
    </RouteContext.Provider>
  );
};

export const useRouteInfo = () => useContext(RouteContext);