
export default {
    config: {
        initialColorModeName: 'default',
        useColorSchemeMediaQuery: false,
    },
    colors: {
        text: "#fff",
        textAlt: '#000',
        white: '#ffffff',
        black: '#000000',
        grey: '#cccccc',
        dark:'#000000',
        background: "#000",
        backgroundAlt: "#fff",
        secondaryButtonBG: "white",
        secondaryHoverBG: "black",
        secondaryHoverText:"white",
        secondaryButtonBorder: "black",
        primary: "#639",
        secondary: "#ff6347",
        'video': {
            inversity: '0%',
            dimmable:1
        },
        modes: {
            dark: {
                text: '#666',
                textAlt: '#666',
                background: '#000',
                backgroundAlt: "#000",
                secondaryButtonBG:"black",
                secondaryHoverBG: "#666",
                secondaryHoverText:"black",
                secondaryButtonBorder: "#666",
                'video': {
                    inversity: '100%',
                    dimmable:0.5
                }
            }
        }
    },
    space: [0, 4, 8, 16, 32, 64, 128, 256],
    fonts: {
        body: "Mori, system-ui, sans-serif",
        sans: "Mori, system-ui, sans-serif",
        heading:"Mori, system-ui, sans-serif"
    },
    fontWeights: {
        light: 100,
        regular: 300,
        medium: 500,
        bold: 700,
        heavy: 900,
        },
    
    lineHeights: {
        narrow: 1.2,
        body: 1.5,
        heading: 1.125,
    },
    fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 72, 110],
    headingSizes: [14, 16, 18, 28, 38, 51, 67, 90, 165, 256],
    sizes: {
        wide: 2048,
        container: 1024,
        fullHeight: '100vh',
        fullWidth: '100vw'
    },
    heading: {
        default: {},
        introLarge: {
            fontSize: [6, 7, 8], 
        }
    },
    headers: {
        default: {
            color: 'text',
            transition: 'color 1s ',
            'a': {
                transition: 'color 1s ',
                color: 'text',
            },
            'svg': {
                transition: 'fill 1s , stroke 1s ',
                fill: 'text',
                stroke:'text'
            },
            '&.navDark,&.startDark': {
                    transition: 'color 1s ',
                    color: 'textAlt',
                'a': {
                    color: 'textAlt',
                },
                'svg': {
                    fill: 'textAlt',
                    stroke:'textAlt'
                },
            }
        },
        secondary: {
            color: 'textAlt',
            'a': {
                transition: 'color 1s ',
                color: 'textAlt',
            },
            'svg': {
                transition: 'fill 1s , stroke 1s ',
                fill: 'textAlt',
                stroke:'textAlt'
            },
            '&.navDark,&.startDark': {
                    color: 'textAlt',
                'a': {
                    color: 'textAlt',
                },
                'svg': {
                    fill: 'textAlt',
                    stroke:'textAlt'
                },
            }
        }
    },
    footers: {
        default: {
            color: 'text',
            background: 'background',
            'a': {
                color: 'text',
            }
        },
        secondary: {
            color: 'textAlt',
            background: 'backgroundAlt',
            'a': {
                color: 'textAlt',
            },
            'svg': {
                stroke:'textAlt'
            }
        }
    },
    contactForm: {
        default: {
            color: 'text',
            background: 'background',
            'a': {
                color: 'text',
            }
        },
        secondary: {
            color: 'textAlt',
            background:'backgroundAlt',
        }
    },
    text: {
        default: {
            lineHeight:'narrow',
            fontSize: 4, 
        },
        subdued: {},
            color:'red',
        nav: {
            fontSize: ['12px', 2, 2, 2], 
        },
        display: {
            fontSize: '6vw', 
        },
        introTextSmall: {
            fontSize: [5, 6, 7], 
        },
        WWDintro: {
            fontSize: ['7vw', '3.5vw', 6], 
            fontWeight: 'bold',
            title: {
                fontSize: [6, 7, 8], 
            },
            subHead: {
                fontSize: [3, 4, 5], 
            },
            body: {
                fontWeight:'regular',
                fontSize: [2, 3, 3], 
            },
            ' div .ls, div .rs, .headline': {
                fontWeight:'regular',
                fontSize: [ 6, 6, 8], 
                lineHeight:['55px','70px','75px']
            },
            'strong': {
                fontWeight: 'bold',
                display:['block','initial']
            },
            '.balanceOuter': {
                marginTop: ['20px', '0px'],
                marginBottom:['40px','0px']
            },
            'span.with': {
                fontWeight: 'regular',
                fontSize: [4, 6, 6], 
                lineHeight:['30px','70px','75px']
            },
            'span': {
                display:'block'
            },
            '.ls': {
                textAlign: ['center','right'],
                marginRight:['0px','10px']
            },
            '.rs': {
                textAlign: ['center','left'],
                marginLeft:['0px','10px']
            },
            '.textClip': {
                height: ['55px','70px','75px'],
                overflow: 'hidden',
                '&.withTextClip': {
                    height: ['30px', '70px', '75px'],
                }
            },
            '.animationHolder video': {
                width: ['120vw', '90vw', '70vw'],
            },
            '.animation4 video': {
                marginLeft: 'auto',
                marginRight: 'auto',
                width: ['160vw', '90vw', '80vw'],
                objectPosition: 'bottom center'
            },
            '.animation5 video': {
                width: ['100%', '90vw', '70vw'],
            },
        },
        textSmall: {
            fontSize: [1, 1, 2, 2], 
            lineHeight: 'body', 
            fontWeight: 'regular',
            '&.light': {
                opacity: 0.5,
            },
        },
        textMedium: {
            fontSize: [1, 1, 2, 3], 
            lineHeight: 'body', 
            fontWeight: 'regular',
            '&.light': {
                opacity: 0.5,
            },
        },
        textLarge: {
            fontSize: [1, 2, 3, 4], 
            lineHeight: 'body', 
            padding: 0,
            '&.light': {
                opacity: 0.5,
            },
        },
        nextProjectLinker: {
            fontSize: [2], 
        },
        pageSubTitle: {
            fontSize: [4, 5, 6, 6], //subtitle on contact page
            fontWeight:'regular',
            
        },
        tiny: {
            fontSize: ['10px','12px','14px'], 
        },
        categoryLink: {
            color: 'text',
            'a span': {
                color: 'text',
                fontWeight:'regular',
            },
            '&:hover': {
            },
            '[aria-current] :not(.slash)': {
                //fontWeight:'bold',
                //textDecoration: 'underline',
            },
            '&.categoryParent a': {
                fontSize: [1, 2, 2, 2],

            },
            ':not(.categoryParent) a': {
                display: 'flex',
                flexDirection: 'row',
                '&:not([aria-current]), .slash': {
                }
            }
        },
        largeBody: {
            fontSize: [2, 3, 4], 
            lineHeight:'narrow',
            fontWeight:'light',
        },
        address: {
            fontSize: [1, 2, 3], 
            textAlign:'center'
        },
        disciplineText: {
            color: 'text',
            fontWeight:'bold',
            fontSize: ['8.5vw', '5vw', 7], 
            letterSpacing: ['-1px', '-1px', '-1px'], 
            marginRight:['1px','5px']
        }
    },
    buttons: {
        primary: {
            border: 'solid',
            bg: 'background',
            color:'text',
            borderWidth:'2px',
            borderColor:'backgroundAlt',
            borderRadius: '20px',
            padding:['10px 30px'],
            fontSize: [1, 2, 3], 
            '&:hover': {
                bg: 'backgroundAlt',
                color:'textAlt',
            }
        },
        static: {
            border: 'solid',
            bg: 'black',
            color:'white',
            borderWidth:'2px',
            borderColor:'white',
            borderRadius: '20px',
            padding:['10px 30px'],
            fontSize: [1, 2, 3], 
            '&:hover': {
                bg: 'white',
                color:'black',
            }
        },
        secondary: {
            border: 'solid',
            bg: 'secondaryButtonBG',
            borderWidth:'2px',
            borderColor:'secondaryButtonBorder',
            color: 'textAlt',
            borderRadius: '20px',
            padding:['10px 30px'],
            fontSize: [1, 2, 3], 
            '&:hover': {
                bg: 'secondaryHoverBG',
                color:'secondaryHoverText',
            }
        },
        
    },
    links: {
        default: {
            color:'textAlt',
            textDecoration: 'underline',
            '&:hover': {
                textDecoration: 'none',
            },
            ':visited': {
                color:'textAlt',
            }
        },
        bold: {
            fontWeight: 'bold',
        },
        foot: {
            fontWeight: 'bold',
            textDecoration: 'none',
            ':hover': {
                textDecoration: 'underline',
            },
        }
    },
    structure: {
        scrollSnapContainer: {
            '&.nav_hide': {
                'header': {
                    marginTop: '-98px',
                }
            },
            '&.nav_blur': {
                'header': {
                    backdropFilter: 'blur(10px)',
                    webkitBackdropFilter:'blur(10px)',
                }
            },
        },
        siteHeader: {
            pointerEvents: 'none',
            mixBlendMode: 'difference',
            top: 0,
            position: 'fixed',
            width: '100%',
            zIndex: 9,
            transition: 'all 1s ease-in-out',
            'nav': {
                alignItems: 'center',
                paddingY: [2, 3, 4],
                paddingRight: [2, 3, 4],
                'a': {
                    paddingLeft: [2, 3, 4],
                    transition: 'color 1s ',
                    color: 'text',
                    
                },
            },
            'a': {
                pointerEvents: 'all',
            },
            
            'svg': {
                transition: 'fill 1s , stroke 1s ',
                fill: 'text',
                stroke: 'text',
                width: ['60px', '80px', '100px'],
                paddingY: [2, 3, 4],
                paddingLeft: [2, 3, 4],
            },

            /** 
            ///// light
            '&.negative': {
                color: 'text',
                'a': {
                    transition: 'color 1s ',
                    color: 'text',
                },
                'svg': {
                    transition: 'fill 1s , stroke 1s ',
                    fill: 'text',
                    stroke:'text'
                },
            },
            ///// dark
            '&.positive': {
                color: 'textAlt',
                'a': {
                    transition: 'color 1s ',
                    color: 'textAlt',
                },
                'svg': {
                    transition: 'fill 1s , stroke 1s ',
                    fill: 'textAlt',
                    stroke:'textAlt'
                },
            },
            */
        },
        workSlidingHeader: {
            transition: 'all 1s ',
            position: 'relative',
            '.BottomHeaderTitle,.TopHeaderTitle': {
                width: '100%',
                position: 'absolute',
                zIndex: '9'
            },
            'h1, a': {
                transition: 'color 1s ',
                color: 'text',
            },
            '&.navDark': {
                'h1, a': {
                    transition: 'color 1s ',
                    color: 'textAlt',
                }
            }
        },
        projectBoxed: {
            maxWidth: '1600px',
            margin:'0 auto',
            flexDirection: ['row'],
            paddingX: [2, 3, 4],
            paddingY: [4, 4, 5],

            /////////////////////////// full width elemenst
            '&.fullWidth': {
                maxWidth: '100%',
                padding:0,
            },
            '&.textBlock': {
                '.left': {
                    paddingRight: [2, 3, 4],
                    textAlign: ['left', 'left'],
                    width: ['100%', '50%'],
                    marginRight:[0, '50%'],
                },
                '.center': {
                    textAlign: ['left', 'center'],
                    width: ['100%', '50%'],
                    margin:'0 auto',
                },
                '.right': {
                    paddingLeft: [0, 3, 4],
                    textAlign: ['left', 'left'],
                    width: ['100%', '50%'],
                    marginLeft:[0, '50%'],
                },
                'a': {
                    color: 'inherit',  
                    textDecoration:'underline'
                }
            },
            /////////////////////////// Single Image
            '&.singleImage': {
                paddingX: [3, 4, 4],
                paddingY: [5, 3, 6],  
            },
            /////////////////////////// Video - vimeo element
            '&.videoBlock': {
                
            },
            /////////////////////////// Image carousel
            '&.imageCarousel': {
                paddingX: [2, 3, 4],
                paddingY: [5, 3, 4],
                '.navLeft,.navRight': {
                    'svg': {
                        fill: 'text',
                        width: '40px',
                        height: '40px',
                    },
                },
            },
            /////////////////////////// blockImageGrid
            '&.blockImageGrid': {
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                alignItems: 'stretch',
                width: '100%',
                flexDirection: 'row',
                paddingX: [2, 3, 4],
                paddingY: [5, 3, 4],
                columnGap: [3, 4, 5],
                rowGap: [3, 4, 5],
                '.gatsby-image-wrapper': {
                    width: '33%',
                    flexGrow: 1,
                },
            },
            ////////////////////////// Project description
            '&.projectDescription': {
                display: 'flex',
                flexDirection:['column','row'],
                margin: '0 auto',
                'div': {
                    width: ['100%', '50%',],
                    paddingY: [2, 3, 4],
                    '&:nth-of-type(1)': {
                        paddingRight: [2, 3, 4],
                    },
                    '&:nth-of-type(2)': {
                        paddingLeft: [0, 3, 4],
                    },
                    'p': {
                        margin: 0
                    },
                    'a': {
                        color: 'inherit',  
                        textDecoration:'underline'
                    }
                },
            },
            ////////////////////////// Proejcts end
            '&.projectsEnd': {
                height:'300px',
            },
            ////////////////////////// Next Project pointer
            '&.nextProject': {
                color:'textAlt',
                background:'text',
                paddingX: [2, 3, 4],
                maxWidth:'100%',
                paddingBottom:[2, 3, 4],
            },
        },
        nextProject: {
            marginTop:'-200px'
        },
        intro_video_section: {
            'video': {
                objectFit: ['contain']
            }
        },
        video_section: {
            'video': {
                objectFit: ['cover','contain']
            }
        },
        pageTitle: {
            alignItems: 'end',
            height: [ '10vh','20vh','20vh'],
            padding:[2, 3, 4]
        },
        disciplineWrapper: {
            position: 'relative',
            margin:'0 auto',
            //height:'100vh',
            //flexGrow: 1,
            display: 'flex',
            flexWrap:  'wrap',
            justifyContent:'center',
            alignContent:'center',
            alignItems: 'center',
            width: ['90vw', '90vw', '80vw', '60vw','50vw'],
        },
        bigEnoughWrapper: {
            textAlign: 'center',
            position: 'absolute',
            top: '0',
            fontWeight: 'bold',
            //height:'100vh',
            display: 'flex',
            flexWrap:  'wrap',
            flexDirection: 'column',
            alignItems:'center',
            justifyContent:'center',
            alignContent: 'center',
            width: '100%',
            
        },
        bigEnoughInnerWrapper: {
            width:['80vw','70vw','60vw'],
            margin:'0 auto',
            
        },


        
        nextProject: {
            margin: '0 auto',
            flexDirection:['row'],
            paddingX: [2, 3, 4],
            paddingY: [4, 4, 5],
            'p': {
                color:'dark'
            }
        },
     
        cmsText: {
            maxWidth: '1600px',
            margin: '0 auto',
            'h2': {
                fontSize: [3, 5, 6], 
                lineHeight: 'initial'
            },
            'h3': {
                fontSize: [2, 4, 5], 
                lineHeight: 'initial'
            }

        },
        projectTitle: {
            display:'flex',
            alignItems: ['left','center'],
            justifyContent:'space-between',
            alignItems:['start','end'],
            margin: '0 auto',
            flexDirection:['column','row'],
            paddingBottom: [2, 3, 4],
            paddingX: [2, 3, 4],
            'h1,h2': {
                fontWeight:'300',
                fontSize: [5],
                textDecoration: 'none',
                color:'text',
            },
        },
        catLinks: {
            display:'flex',
            flexWrap: ['wrap', 'nowrap'],
            'span': {
                fontSize: [1, 2, 2], 
                fontWeight:'regular',
            }
            
        },
        projectDescriptionOuter: {
            flexDirection:['column','row'],
        },
        
        
    },
    forms: {
        input: {
            borderRadius: 0,
            border: 'none 0px',
            borderBottom: '1px solid grey',
            marginBottom:[3]
        },
        textarea: {
            borderRadius: 0,
            width: '100%',
            border: 'none 0px',
            borderBottom:['0px solid grey','1px solid grey']
        }
    }
    
}